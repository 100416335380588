const About = () => {
  return (
    <section
      id="about"
      className="about"
    //style="padding: 150px 0 0 0 !important;"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3 data-aos="fade-up">Procedure for Admission to Jr College: </h3>
            <p data-aos="fade-up">
              1. Students who have passed S.S.C. Examination or ICSE / CBSE are
              eligible for admission. The admissions are governed by the
              centralised admission procedure and seat allocation as per merit
              list.
            </p>
            <p data-aos="fade-up">
              2. All the admission are subject to availability of seats and
              directions are fixed by Deputy Director of Education and their
              rules prescribed.
            </p>

            <h4 data-aos="fade-up">
              <b>Subject to fulfilment of following: </b>
            </h4>
            <p data-aos="fade-up">
              I) Admission form acccompanied by original documents and two
              attensted copies of - <br />
              &nbsp;&nbsp;&nbsp;&nbsp;a) Statment of marks SSC or equivalent
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;b) Original School Leaving Certificate
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;c) Two recent passport size photographs
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;d) Caste Certificate / Validity / Creamy
              layer Certificate (if applicable)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;e) Photocopy of Aadhar Card
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;f) Eligibility Certificate (if neccessary)
            </p>
            <p data-aos="fade-up">
              II) Fees as notified by the authority will be displayed on the
              notice board.
            </p>
            <br />
            <h3 data-aos="fade-up">Admission for S.Y.J.C. (Std. XII): </h3>
            <p data-aos="fade-up">
              Students who have passed XI Commerce / Science Examination are
              eligible to get admission for XII Commerce.{" "}
            </p>
            <h4 data-aos="fade-up">
              <b>Document to be submitted: </b>
            </h4>
            <p data-aos="fade-up">
              &nbsp;&nbsp;&nbsp;&nbsp;a) Mark List of Std. XI
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;b) Passing Certificate of SSC
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;c) School Leaving Certificate
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;d) Caste Certificate (if necessary)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;e) Eligibility Certificate (if neccessary)
            </p>
            <br />
            <h3 data-aos="fade-up">Curriculum: </h3>
            <h4 data-aos="fade-up">
              <b>
                As per the prescribed structure of Maharashtra State Board. The
                subjects offered:
              </b>
            </h4>
            <table className="col-lg-8 col-md-6">
              <tbody>
                <tr>
                  <td>
                    <h4 data-aos="fade-up">English</h4>
                  </td>
                  <td>
                    <h4 data-aos="fade-up">Marathi</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 data-aos="fade-up">Book Keeping & Accountancy</h4>
                  </td>
                  <td>
                    <h4 data-aos="fade-up">Economics</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 data-aos="fade-up">
                      Organisation of Commerce & Management
                    </h4>
                  </td>
                  <td>
                    <h4 data-aos="fade-up">Secretarial Practices</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 data-aos="fade-up">Environment Education</h4>
                  </td>
                  <td>
                    <h4 data-aos="fade-up">Physical Education</h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h4 data-aos="fade-up">
              <b>Extra activities for practical experience: </b>
            </h4>
            <table className="col-lg-6 col-md-6">
              <tbody>
                <tr>
                  <td>
                    <h4 data-aos="fade-up">Industrial Visit</h4>
                  </td>
                  <td>
                    <h4 data-aos="fade-up">Visits to Bank, CA Offices</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 data-aos="fade-up">Study Tour</h4>
                  </td>
                  <td>
                    <h4 data-aos="fade-up">Guest Lectures, etc.</h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h3 data-aos="fade-up">Examination: </h3>
            <p data-aos="fade-up">
              Annual examination for std XI is conducted by the Jr. College.
            </p>
            <h4 data-aos="fade-up">
              <b>
                Following examinations and tests are conducted by the college
                during the academic year:
              </b>
            </h4>
            <p data-aos="fade-up">
              &nbsp;&nbsp;&nbsp;&nbsp;1) Mid-term / Unit Test of 25 marks of
              eacch subject in each term
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;2) Terminal Examination (Term End
              Examination) of 50 marks of each subject.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;3) Annual Examination of 100 marks of each
              subject.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;4) Examination pattern of Physical
              Education and Environment Education will be informed to the
              students by their respective teachers.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;5) An average of all the tests / exams is
              taken for prommoting the students to std. XII.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;6) 35% marks are required in each subject
              to promote to std. XII.
            </p>
            <br />
            <h3 data-aos="fade-up">Timing: </h3>
            <table width="500px">
              <tbody>
                <tr>
                  <td width="30%">
                    <h4 data-aos="fade-up">
                      <b>Office:</b>
                    </h4>
                  </td>
                  <td>
                    <h4 data-aos="fade-up">
                      <b>11:30 AM - 5:30 PM</b>
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 data-aos="fade-up">
                      <b>Jr. College:</b>
                    </h4>
                  </td>
                  <td>
                    <h4 data-aos="fade-up">
                      <b>12:00 PM - 5:00 PM</b>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
