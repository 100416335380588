import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/layout/Footer";
import School from "./pages/School";
import Vidyapratishthan from "./pages/VidyaPratishthan";
import Topbar from "./components/layout/Topbar";
import TermsAndPrivacy from "./pages/TermsAndPrivacy";
import ScrollButton from "./components/layout/ScrollButton";
import JrCollege from "./pages/JrCollege";
import GallaryPage from "./pages/GallaryPage";

function App() {
  return (
    <div className="App">
      <Topbar />
      <Routes>
        <Route path="/" element={<Vidyapratishthan />} />
        <Route path="/school" element={<School />} />
        <Route path="/jrCollege" element={<JrCollege />} />
        <Route path="/termsAndPrivacy" element={<TermsAndPrivacy />} />
        <Route path="/gallary" element={<GallaryPage />} />
      </Routes>
      <Footer />
      <ScrollButton />
    </div>
  );
}

export default App;
