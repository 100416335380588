const Faq = () => {
  return (
    <section id="faq" className="faq section-bg">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-up">Code Of Conduct (Discipline & Rules)</h2>
        </div>
        <div className="faq-list">
          <ul>
            <li data-aos="fade-up">
              <div
                id="faq-list-1"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  1) Students must attend lectures, practicals, test &
                  examinations as per schedule.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="100">
              <div
                id="faq-list-2"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  2) 75% attendance for all subjects is compulsory. If the
                  atttendance is not satisfactory, he/she wil not be allowed to
                  appear for final examination.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="200">
              <div
                id="faq-list-3"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  3) Students will get Identity Card from the college authority.
                  It is mandatory to wear neat and clean college uniform along
                  with I-card.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300">
              <div
                id="faq-list-4"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  4) ON the loss of I-card, a student should seek duplicate
                  ccard immediately from the Jr. college authority by paying
                  necessary fees and fine.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <div
                id="faq-list-5"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  5) It is expected that the Jr. College students observe proper
                  manners and discipline inside and outside the college
                  premises.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <div
                id="faq-list-5"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  6) All the students should strictly follow the rules and
                  instructions of HSC Board, Departmentt of Education,
                  Principal, Teachers and other administrative officials.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <div
                id="faq-list-5"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  7) Students should be meticulous about filling the examination
                  and eligibility forms, if any, on or before the stipulated
                  datae declared. In case of failure to fill these forms, the
                  student will be solely held responsible and will have to bear
                  the consequences.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <div
                id="faq-list-5"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  8) Stern action will be taken against the students in case of
                  undisciplined behavior, disobeying of rules and orders.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <div
                id="faq-list-5"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  9) Authorities have full right to modify the rules whenver
                  necessary and following of such modified rules is obligatory
                  for the students.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <div
                id="faq-list-5"
                className="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  10) In case of any dispute, the decision of the Principal &
                  college authorites will be final.
                </p>
              </div>
            </li>
          </ul>
        </div>

        <h3 className="text-danger" data-aos="fade-up">
          Notice:{" "}
        </h3>
        <p data-aos="fade-up">
          All the concerned students and parents should note "if any incident of
          ragging comes to the noticce of the authorities, the concerned
          students shall be given liberty to explain and if his/her explanation
          is not found satisfactory, the college authorites would expel himm/her
          from the Jr. College."
        </p>
      </div>
    </section>
  );
};

export default Faq;
