import { useState } from "react";
import { send } from "emailjs-com";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
const ContactUs = () => {
  const { register, handleSubmit, formState } = useForm();

  const [isSubmitting, setUseSubmitting] = useState(false);

  const onSubmit = (data) => {
    console.log(data);
    setUseSubmitting(true);
    send("service_vb4hwh8", "template_twxl997", data, "ehTA1cKoUoy0Pt518")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setUseSubmitting(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your message has been sent. Thank you!",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setUseSubmitting(false);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Your message could not be sent. Please try again!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <section id="contact" className="contact section-bg">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-up">Contact Us</h2>
          <p data-aos="fade-up">
            Please contact us if you want to know more. We would be happy to
            assist you further.
          </p>
        </div>

        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-4 mt-4" data-aos="fade-up">
            <div className="info-box">
              <i className="bx bx-map"></i>
              <h3>Our Address</h3>
              <p>
                Sr.No. 11/2, Shahu Colony, Lane No. 01, Near Cummins Eng.
                College, Karve Nagar, Pune - 411052
              </p>
            </div>
          </div>

          <div
            className="col-xl-4 col-lg-4 mt-4"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="info-box">
              <i className="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <p>vidyapratishthanm@gmail.com</p>
              <p>&nbsp;</p>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-4 mt-4"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="info-box">
              <i className="bx bx-phone-call"></i>
              <h3>Call Us</h3>
              <p>
                +91 20 25471977
                <br />
                +91 20 25470414
              </p>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="col-xl-12 col-lg-12 mt-4">
            <form
              action=""
              name="contactUs"
              className="php-email-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row">
                <div className="col-md-6 form-group mb-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                    {...register("name", {
                      required: true,
                      minLength: 4,
                      maxLength: 100,
                    })}
                  />
                  {formState.errors.name && (
                    <div className="validate">
                      <p>Please enter at least 4 chars and maximum 100 chars</p>
                    </div>
                  )}
                </div>
                <div className="col-md-6 form-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                    {...register("email", {
                      required: true,
                      pattern: /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i,
                    })}
                  />
                  {formState.errors.email && (
                    <div className="validate">
                      <p>Please enter a valid email</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                  {...register("subject", {
                    required: true,
                    minLength: 8,
                    maxLength: 100,
                  })}
                />

                {formState.errors.subject && (
                  <div className="validate">
                    <p>Please enter 8 to 100 chars of subject</p>
                  </div>
                )}
              </div>
              <div className="form-group mb-3">
                <textarea
                  className="form-control"
                  name="message"
                  id="message"
                  rows="5"
                  data-rule="required"
                  data-msg="Please write something for us"
                  placeholder="Message"
                  {...register("message", {
                    required: true,
                    minLength: 15,
                    maxLength: 200,
                  })}
                ></textarea>

                {formState.errors.message && (
                  <div className="validate">
                    <p>Please enter 15 to 200 chars of message</p>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button disabled={isSubmitting} type="submit">
                  {isSubmitting && (
                    <span className="spinner-grow spinner-grow-sm"></span>
                  )}
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
