import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">&nbsp;</div>
          <div className="copyright col-xl-3 col-lg-3">
            <Link to="/termsAndPrivacy">Terms of service</Link>
          </div>
          <div className="credits col-xl-3 col-lg-3">
            <Link to="/termsAndPrivacy">Privacy policy</Link>
          </div>
        </div>
      </div>

      <div className="container d-lg-flex py-4 section-bg">
        <div className="me-lg-auto text-center text-lg-left">
          <div className="copyright">All Rights Reserved</div>
          <div className="credits">
            Developed by{" "}
            <a href="https://560mTEK.com/" target="_blank">
              560mTEK
            </a>
          </div>
        </div>
        <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
          <a
            href="https://www.facebook.com/560mTEK/"
            className="facebook"
            target="_blank"
          >
            <i className="bx bxl-facebook"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/560mtek"
            className="linkedin"
            target="_blank"
          >
            <i className="bx bxl-linkedin"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
