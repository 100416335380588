import { Link } from "react-router-dom";
const NavLinks = (props) => {
    const scrollToKinderGarten = () => {
        const section = document.querySelector("#kinderGarten");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        if (props.isMobile)
            props.closeMobileMenu();
    };

    const scrollToPrimary = () => {
        const section = document.querySelector("#primary");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        if (props.isMobile)
            props.closeMobileMenu();
    };

    const scrollToSecondary = () => {
        const section = document.querySelector("#secondary");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        if (props.isMobile)
            props.closeMobileMenu();
    };

    return <ul>
        <li>
            <Link to="/" onClick={() => props.isMobile && props.closeMobileMenu()}>Home</Link>
        </li>
        <li>
            <Link onClick={scrollToKinderGarten}>Kinder Garten</Link>
        </li>
        <li>
            <Link onClick={scrollToPrimary}>Primary</Link>
        </li>
        <li>
            <Link onClick={scrollToSecondary}>Secondary</Link>
        </li>
        <li>
            <Link to="/jrCollege" onClick={() => props.isMobile && props.closeMobileMenu()}>Jr College</Link>
        </li>
        <li>
            <Link to="/gallary" onClick={() => props.isMobile && props.closeMobileMenu()}>News & Events</Link>
        </li>

    </ul>

}

export default NavLinks;