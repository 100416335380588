import { Link } from "react-router-dom";
const NavLinks = (props) => {

    const scrollToFaq = () => {
        const section = document.querySelector("#faq");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        if (props.isMobile)
            props.closeMobileMenu();
    };

    return <ul>
        <li>
            <Link to="/" onClick={() => props.isMobile && props.closeMobileMenu()}>Home</Link>
        </li>
        <li>
            <Link onClick={scrollToFaq}>Code Of Conduct</Link>
        </li>        <li>
            <Link to="/school">School</Link>
        </li>
        <li className="active">
            <Link to="/jrCollege">Jr College</Link>
        </li>
        <li>
            <Link to="/gallary" onClick={() => props.isMobile && props.closeMobileMenu()}>News & Events</Link>
        </li>

    </ul >;
}

export default NavLinks;