import { Link } from "react-router-dom";
const NavLinks = (props) => {
    const scrollToContact = () => {
        const section = document.querySelector("#contact");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        if (props.isMobile)
            props.closeMobileMenu();
    };

    const scrollToDonations = () => {
        const section = document.querySelector("#donations");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        if (props.isMobile)
            props.closeMobileMenu();
    };

    return <ul>
        <li>
            <Link to="/" onClick={() => props.isMobile && props.closeMobileMenu()}>Home</Link>
        </li>
        <li>
            <Link to="/school" onClick={() => props.isMobile && props.closeMobileMenu()}>Education</Link>
        </li>
        <li className="active">
            <Link to="/gallary" onClick={() => props.isMobile && props.closeMobileMenu()}>News & Events</Link>
        </li>
    </ul>

}

export default NavLinks;