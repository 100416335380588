import React from "react";
import Header from "../components/jrCollege/Header";
import Hero from "../components/jrCollege/Hero";
import About from "../components/jrCollege/About";
import Faq from "../components/jrCollege/Faq";
const JrCollege = () => {
  return (
    <React.Fragment>
      <Header />
      <Hero />
      <main id="main">
        <About />
        <Faq />
      </main>
    </React.Fragment>
  );
};

export default JrCollege;
