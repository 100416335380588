const Topbar = () => {
  return (
    <section id="topbar" className="d-none d-lg-block">
      <div className="container d-flex">
        <div className="contact-info me-auto">
          <ul>
            <li>
              <i className="icofont icofont-envelope"></i>
              <a href="mailto:vidyapratishthanm@gmail.com">
                vidyapratishthanm@gmail.com
              </a>
            </li>
            <li>
              <i className="icofont icofont-phone"></i>+91 20 25477409 / +91 20
              25470414
            </li>
            <li>
              <i className="icofont icofont-clock-time icofont-flip-horizontal"></i>{" "}
              Mon-Fri 10:30am - 5:00pm
            </li>
          </ul>
        </div>
        <div className="cta"></div>
      </div>
    </section>
  );
};

export default Topbar;
