import { Link } from "react-router-dom";
const LogoImage = (props) => {
  const minWidthStyle = {
    'min-width': '45%'
  }
  return (
    <div className="logo" style={minWidthStyle}>
      <h1 className="text-light w-100">
        <Link to="/">
          <div className="container-fluid m-0 p-0 w-100">
            <div className="row justify-content-center m-0 p-0 w-100">
              <div className="col-2 m-0 p-0">
                <img src="./img/logo.jpg" />
              </div><div className="col-10 m-0 p-0 d-flex align-items-center">
                <span>{props.title}</span></div>
            </div></div>
        </Link>
      </h1>
    </div>
  );
};

export default LogoImage;
