import { Link } from "react-router-dom";
const NavLinks = (props) => {
    const scrollToPrivacy = () => {
        const section = document.querySelector("#privacy");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        if (props.isMobile)
            props.closeMobileMenu();
    };
    const scrollToTerms = () => {
        const section = document.querySelector("#terms");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        if (props.isMobile)
            props.closeMobileMenu();
    };

    return <ul>
        <li>
            <Link to="/" onClick={() => props.isMobile && props.closeMobileMenu()}>Home</Link>
        </li>
        <li>
            <Link onClick={scrollToPrivacy}>Privacy Policy</Link>
        </li>
        <li>
            <Link onClick={scrollToTerms}>Terms of Service</Link>
        </li>
        <li>
            <Link to="/school" onClick={() => props.isMobile && props.closeMobileMenu()}>School</Link>
        </li>
        <li>
            <Link to="/jrCollege" onClick={() => props.isMobile && props.closeMobileMenu()}>Jr College</Link>
        </li>
        <li>
            <Link to="/gallary" onClick={() => props.isMobile && props.closeMobileMenu()}>News & Events</Link>
        </li>

    </ul>

}

export default NavLinks;