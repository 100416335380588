import LogoImage from "../header/LogoImage";
import Navigation from "../layout/Navigation";
import NavigationMobile from "../layout/NavigationMobile";
import NavLinks from "./NavLinks";

const Header = () => {
  return (
    <header id="header">
      <div className="container d-flex">
        <LogoImage title="Vidya Pratishthan, Maharashtra" />
        <Navigation><NavLinks /></Navigation>
        <NavigationMobile>{(isMobile, closeMobileMenu) => (
          <NavLinks isMobile={isMobile} closeMobileMenu={closeMobileMenu} />
        )}
        </NavigationMobile>
      </div>
    </header>
  );
};

export default Header;
