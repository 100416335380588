import React from "react";
import { Link } from "react-router-dom";
import ContactUs from "../components/common/ContactUs";
import Header from "../components/vidyaPratishthan/Header";
import Hero from "../components/vidyaPratishthan/Hero";
import LocalCommitee from "../components/vidyaPratishthan/LocalCommitee";
const Vidyapratishthan = () => {
  const scrollToAbout = () => {
    const section = document.querySelector("#about");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <React.Fragment>
      <Header />
      <Hero />

      <main id="main">
        <section id="why-us" className="why-us">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-5" data-aos="fade-up">
                <div className="content">
                  <h3>Vidya Pratishthan, Maharashtra</h3>
                  <p>
                    Aims to provide quality education to the children from
                    economically weaker sections of the society
                  </p>
                  <div className="text-center">
                    <Link className="more-btn" onClick={scrollToAbout}>
                      About Us <i className="bx bx-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 d-flex">
                <div className="icon-boxes d-flex flex-column justify-content-center">
                  <div className="row">
                    <div
                      className="col-xl-4 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-buildings"></i>
                        <h4>Shri Saraswati Vidya Mandir school, Pune</h4>
                        <p>
                          One of the aspiring educational, cultural and social
                          organizations in the area.
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-xl-4 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-user"></i>
                        <h4>Students</h4>
                        <p>
                          Majority of our students belong to the financially
                          weaker section of the society.
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-xl-4 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-book-reader"></i>
                        <h4>Vidya Bharti</h4>
                        <p>
                          We educate children based on Vidya Bharti's five
                          principals: Music, Yoga, Sanskrit, Moral Education and
                          Physical Education.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" name="about" className="about section-bg">
          <div className="container">
            <div className="row">
              <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                <a href="https://youtu.be/sMe9NeyoI-s" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
              </div>

              <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                <h4 data-aos="fade-up">About us</h4>
                <h3 data-aos="fade-up">Vidya Pratishthan, Maharashtra</h3>
                <p data-aos="fade-up">
                  Vidya Pratishthan Maharashtra's Shri Saraswati Vidya Mandir
                  school, Pune aims to provide quality education to the children
                  from economically weaker sections of the society. The school
                  caters to the slum communities/ Seva Vastis around Dahanukar
                  Colony, Kothrud, and Karvenagar. Majority of our students
                  belong to the financially weaker section of the society. Most
                  of the parents are construction workers, artisans, auto
                  rickshaw drivers, house workers, and doing minimal jobs. The
                  parents of our students have not been fortunate enough to
                  receive minimum essential education. Despite of this, our
                  school has seen consistent growth in admissions from the
                  working class of the society due to the quality education we
                  provide.
                </p>
                <p data-aos="fade-up">
                  The school was founded in June 1986 by social visionaries of
                  that time, namely -Late Sureshrao Chandekar, Late Anna
                  Gokhale, Late Prabhakar Nerlekar, Late Raghunath alias Tatya
                  Acharya.
                </p>
                <p data-aos="fade-up">
                  The school is now one of the aspiring educational, cultural
                  and social organizations in the area. Our school is associated
                  with Vidya Bharati. We educate children based on Vidya
                  Bharti's five principals: Music, Yoga, Sanskrit, Moral
                  Education and Physical Education.
                </p>
                <p data-aos="fade-up">
                  We also emphasize on educational subjects that are important
                  in current times - <br />
                  1. Computer literacy <br />
                  2. Environmental Studies - Cleanliness <br />
                  3. Scientific Attitude <br />
                  4. Inculcation of good habits, Manners (Sanskar) and Physical,
                  Mental Health
                </p>
                <p data-aos="fade-up">
                  Currently there are 1200 students enrolled in the school from
                  Pre-primary to Junior College-Commerce. The school owns a
                  fully furnished building at Karvenagar, Pune, and spread over
                  an area of 17000sf.
                </p>
                <p data-aos="fade-up">
                  We have a staff of 65 consists of teachers and sevaks, of
                  which, only 17 get government salary. Other staff members are
                  paid by our institution.
                </p>
                <p data-aos="fade-up">
                  Taking into consideration the financial conditions of the
                  parents, we charge a minimal fee of Rs. 5000 for the whole
                  academic year. This creates a need for support from the people
                  who can contribute to education of needy.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="activities">
          <div className="container">
            <div className="row">
              <h3 data-aos="fade-up">Activities</h3>
              <p data-aos="fade-up">
                Along with the curriculum directed by the state government, we
                also carry out various activities in the school, such as:
              </p>
              <p data-aos="fade-up">
                <ol>
                  <li>Discourses by experts on various topics</li>
                  <li>Host Inter-School Elocution competition</li>
                  <li>
                    Drawing, storytelling, essay, handwriting, sports
                    competitions
                  </li>
                  <li>
                    Nurturing talent development through games - HasathKhelat
                    Buddhi Vikas
                  </li>
                  <li>
                    Inculcating reading culture in collaboration with Doorstep
                    school
                  </li>
                  <li>Special efforts for remedial students</li>
                  <li>
                    Education in mother tongue -Marathi; equal efforts for
                    fluency in English
                  </li>
                  <li>In-house training center for teachers</li>
                  <li>Spoken English from 5th Std</li>
                  <li>Annual Student Magazine - PRERNA</li>
                  <li>
                    Lessons by young talented youth from IT sector - Seva Sahyog
                  </li>
                  <li>
                    Counseling to Students and their parents by Blossom Sanstha,
                    Pune
                  </li>
                  <li>
                    Science project for students by Bhagini Nivedita Sanstha
                    Pune
                  </li>
                </ol>
              </p>
              <div className="body"></div>
            </div>
          </div>
        </section>
        <section id="techEducation" className="section-bg">
          <div className="container">
            <div className="row">
              <h3 data-aos="fade-up">Technical Education</h3>
              <p data-aos="fade-up">
                We have vocational training unit in our school for 9th and 10th.
                This is done to build their technical skills and increase
                chances of better employment in future. It is done along with
                academics and it helps them to get admission for Engineering,
                Diploma, ITI, and other such fields. Currently, we have
                Mechanical and Electrical Technology Training blended with
                academics for 150 students annually. Following trainings are
                provided - welding, plumbing, fitting, electrical, carpentry,
                two-wheeler repairing. <br />
                All these courses are conducted for a minimal fee from our
                students.
              </p>
            </div>
          </div>
        </section>
        <section id="jrCollegeCommerce">
          <div className="container">
            <div className="row">
              <h3 data-aos="fade-up">Junior College Commerce</h3>
              <p data-aos="fade-up">
                We plan to start courses for 11th and 12th STD students on below
                lines -
              </p>
              <p data-aos="fade-up">
                <ul>
                  <li>Tally with Practice</li>
                  <li>Foreign Language Training</li>
                  <li>Bi-focal technical courses</li>
                  <li>Computer Literacy Courses</li>
                </ul>
              </p>
            </div>
          </div>
        </section>
        <section id="projects" className="section-bg">
          <div className="container">
            <div className="row">
              <h3 data-aos="fade-up">New and Upcoming Projects</h3>
              <p data-aos="fade-up">
                <ol>
                  <li>
                    Infrastructure Development in Karvenagar campus with
                    estimated cost of Rs. 50 lakhs
                  </li>
                  <li>
                    Re-initiation of the following government approved technical
                    courses:
                    <ol>
                      <li>Electrical wireman</li>
                      <li>Mechanical welding (Gas and Electric)</li>
                      <li>Mechanical fitter (Bench Fitter) </li>
                      <li>
                        Automobile scooter, two-wheeler motorcycle servicing{" "}
                      </li>
                      <li>TALLY ERP </li>
                      <li>Diesel Mechanic</li>
                    </ol>
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </section>
        <section id="donations">
          <div className="container">
            <div className="row">
              <h3 data-aos="fade-up">Donation</h3>
              <p data-aos="fade-up">
                We are fortunate to have received help from generous people and
                institutions, and we will be grateful to them forever.
              </p>
              <p data-aos="fade-up">
                We wish our STUDENTS TO BE PATRIOTS AND IDEAL CITIZENS OF
                BHARAT, and for this, all the members of the institution and
                teachers are working with the utmost dedication, focus, and
                persistence.
              </p>
              <p data-aos="fade-up">
                We seek your contribution in - Institution as follows:
                <ol>
                  <li>Infrastructure Development of the School Premises </li>
                  <li>
                    Sponsoring activities like Music, Sports, Drawing, Reading
                    competitions
                  </li>
                  <li>
                    Dattak Palak Yojana - Supporting for School Fees of the most
                    needy students
                  </li>
                  <li>Sponsoring for the salary of the Teachers/ Sevaks</li>
                  <li>Volunteering at School under Seva- Sahyog Program</li>
                </ol>
              </p>
              <p data-aos="fade-up">
                Your Donation is exempt under Section 80G of IT act.
              </p>
              <p data-aos="fade-up">
                Here are the account details - You Can give a Cheque/DD/ and
                Internet Banking
              </p>
              <p data-aos="fade-up">
                <div className="body table-responsive">
                  <table className="table table-bordered" data-aos="fade-up">
                    <tbody>
                      <tr>
                        <td>A/c Name:</td>
                        <td>VIDYA PRATISHTHAN MAHARASHTRA</td>
                      </tr>
                      <tr>
                        <td>A/c Number:</td>
                        <td>031220100003248 (Saving Account)</td>
                      </tr>
                      <tr>
                        <td>IFSC Code:</td>
                        <td>JSBP0000031</td>
                      </tr>
                      <tr>
                        <td>Bank:</td>
                        <td>Janata Sahkari Bank, Karvenagar Branch, Pune</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </p>
              <p data-aos="fade-up">
                We request your kind co-operation and guidance.
              </p>
            </div>
          </div>
        </section>
        <section id="team" className="team section-bg">
          <div className="container">
            <div className="row">
              <div className="section-title">
                <h2 data-aos="fade-up">TRUSTEE DETAILS</h2>
              </div>
              <div className="body table-responsive">
                <table className="table table-bordered" data-aos="fade-up">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Name of Trustee</th>
                      <th>Designation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Mr. Narayan Raghunath Acharya</td>
                      <td>Chairman</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Smt. Ratnaprabha Ramesh Rajhans</td>
                      <td>Secretary</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Mrs. Pratiksha Sadashiv Ranade</td>
                      <td>Member</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Mr. Umakant Vitthal Dharmadhikari</td>
                      <td>Member</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Dr. Sudhir Digambar Chandekar</td>
                      <td>Member</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <LocalCommitee />
        <ContactUs />
      </main>
    </React.Fragment>
  );
};

export default Vidyapratishthan;
