const Hero = () => {
  return (
    <section
      id="hero-school"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="container" data-aos="fade-in">
        <h1>Welcome to Shri Saraswati Vidya Mandir</h1>
        <h2>
          Our Students Grow Up - To Become a Nationalist, Well Cultured and
          Ideal Citizen.
        </h2>
      </div>
    </section>
  );
};

export default Hero;
