import { Link } from "react-router-dom";
const Hero = () => {
  const scrollToAbout = () => {
    const section = document.querySelector("#about");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <section
      id="hero-jrcollege"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="container" data-aos="fade-in">
        <h1>Shri Saraswati Vidya Mandir & Junior College</h1>
        <h2></h2>
        <div className="d-flex align-items-center">
          <i className="bx bxs-right-arrow-alt get-started-icon"></i>
          <Link className="btn-get-started scrollto" onClick={scrollToAbout}>
            About Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;
