const LocalCommitee = () => {
    return <section id="localCommitee" className="team">
        <div className="container">
            <div className="row">
                <div className="section-title">
                    <h2 data-aos="fade-up">LOCAL SCHOOL MANAGEMENT COMMITEE</h2>
                </div>
                <div className="body table-responsive">
                    <table className="table table-bordered" data-aos="fade-up">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Mr. Narayan Raghunath Acharya</td>
                                <td>Chairman</td>
                            </tr>
                            <tr>
                                <td>Smt. Sandhya Vinayak Dambir</td>
                                <td>Secretary</td>
                            </tr>
                            <tr>
                                <td>Mr. Rajesh Bhandarkar</td>
                                <td>Treasurer</td>
                            </tr>
                            <tr>
                                <td>Smt. Bharati Anirudhh Deshpande</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Smt. Ratnaprabha Ramesh Rajhans</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Mr. Prashant Deshpande</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Mr. Chandrakant Kulkarni</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Smt. Pratiksha Sadashiv Ranade</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Smt. Urmila Uday Gujar</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Smt. Amruta Ashok Sarpotdar</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Smt. Yogini Samir Tilak</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Smt. Kanchantai Anagal</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Mr. Umakant Vitthal Dharmadhikari</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Dr. Sudhir Digambar Chandekar</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Mr. Vijay Jayram Dandekar</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Mr. Dattatray Balkrushna Gokhale</td>
                                <td>Member</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

}

export default LocalCommitee;