import React from "react";
import { Suspense, lazy } from 'react';
import Header from "../components/gallary/Header";
const Gallery = lazy(() => import('../components/gallary/Gallary'));

const GallaryPage = () => {
  return (
    <React.Fragment>
      <Header />

      <main id="main">
        <Suspense fallback={<h1>Still Loading…</h1>}>
          <Gallery />
        </Suspense>
      </main>
    </React.Fragment>
  );
};

export default GallaryPage;
