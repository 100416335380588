import classes from "./navigation.module.css";
import { CgMenuRound, CgCloseO } from "react-icons/cg";
import { useState } from "react";

const NavigationMobile = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const hamburgerIcon = <CgMenuRound
        className="mobile-nav-toggle"
        size="40px"
        onClick={() => {
            setIsOpen(!isOpen);
        }}
    />

    const closeIcon = <CgCloseO
        className="mobile-nav-toggle"
        size="40px"
        onClick={() => {
            setIsOpen(!isOpen);
        }}
    />

    const closeMobileMenu = () => { setIsOpen(false) }

    return (
        <nav className={`nav-menu ${classes.mobileNavigation}`}>
            {isOpen ? closeIcon : hamburgerIcon}
            {isOpen && children(true, closeMobileMenu)}
        </nav>
    );
};

export default NavigationMobile;
